import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

// Your Google Maps API key
const GOOGLE_MAPS_API_KEY = 'AIzaSyAvDka_HEWufYVqW_m2KzIfSJEjd9O-gYI';

// The fixed starting address
const START_ADDRESS = "Psichari 1, Metamorfosi 144 52, Greece";

const OptimalRouteMap = ({ addresses, onRouteCalculated }) => {
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const [totalDistance, setTotalDistance] = useState(''); // Total distance in kilometers
  const [totalDuration, setTotalDuration] = useState(''); // Total time in minutes

  const calculateRoute = (map, maps) => {
    if (addresses.length < 1) {
      console.error('At least one address (besides the starting point) is needed to calculate a route');
      return;
    }

    const allAddresses = [START_ADDRESS, ...addresses];
    const directionsService = new maps.DirectionsService();

    if (!directionsRenderer) {
      const renderer = new maps.DirectionsRenderer();
      renderer.setMap(map);
      setDirectionsRenderer(renderer);
    }

    const origin = allAddresses[0];
    const destination = allAddresses[allAddresses.length - 1];
    const waypoints = allAddresses.slice(1, -1).map(address => ({
      location: address,
      stopover: true,
    }));

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        optimizeWaypoints: true,
        travelMode: maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(result);
          const route = result.routes[0];

          // Calculate total distance and total duration
          let totalDistance = 0;
          let totalDuration = 0;
          const details = route.legs.map((leg) => {
            totalDistance += leg.distance.value; // in meters
            totalDuration += leg.duration.value; // in seconds
            return {
              start: leg.start_address,
              end: leg.end_address,
              distance: leg.distance.text, // Human-readable format
              duration: leg.duration.text,  // Human-readable format
            };
          });

          // Convert total distance to kilometers and total duration to minutes
          const totalDistanceKm = (totalDistance / 1000).toFixed(2); // Convert meters to kilometers
          const totalDurationMin = Math.floor(totalDuration / 60); // Convert seconds to minutes

          // Update state to display the totals in the component
          setTotalDistance(totalDistanceKm);
          setTotalDuration(totalDurationMin);

          // Optionally pass the details to the parent component
          if (onRouteCalculated) {
            onRouteCalculated(details, totalDistanceKm, totalDurationMin);
          }
        } else {
          console.error(`Error calculating directions: ${status}`);
        }
      }
    );
  };

  useEffect(() => {
    if (mapInstance && mapsInstance) {
      calculateRoute(mapInstance, mapsInstance);
    }
  }, [mapInstance, mapsInstance, addresses]);

  return (
    <div>
      {/* Display total distance and time */}
      <div style={{ marginBottom: '10px' }}>
        <h4>Optimal Route Summary</h4>
        <p><strong>Total Distance:</strong> {totalDistance} km</p>
        <p><strong>Total Duration:</strong> {totalDuration} minutes</p>
      </div>

      {/* Google Map */}
      <div style={{ height: '300px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultCenter={{ lat: 37.9838, lng: 23.7275 }} // Default center (Athens)
          defaultZoom={12}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setMapInstance(map);
            setMapsInstance(maps);
          }}
        />
      </div>
    </div>
  );
};

OptimalRouteMap.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRouteCalculated: PropTypes.func, // Optional callback for sending route details to parent
};

export default OptimalRouteMap;
