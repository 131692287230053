import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import { getMaterials ,deleteProduct} from "helpers/api_helper"
//redux
import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import DatatableTables from "./datatable"
import { getInvoices as onGetInvoices } from "store/actions"
import { getProducts, patchProduct } from "helpers/api_helper"

const ProductsList = props => {
  //meta title
  document.title = "Invoice List | customers - React Admin & Dashboard Template";
  const [products, setProducts] = useState([])
  const [status, setStatus] = useState(''); // Status state for tracking update progress

  const [loading, setLoading] = useState(true); // To track if the update has been done

  // Simulate an API call to get products
  useEffect(() => {
    const fetchProducts = async () => {
      const fetchedProducts = await getProducts(); // Assume getProducts is a function that fetches the products
      setProducts(fetchedProducts);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  // const deletePrd = async (prdid) => {
  //   try {
  //     const payload = {
  //       resource: [
  //         {
  //           id: prdid,
  //         },
  //       ],
  //     };

  //     console.log(payload)
  
  //     await deleteProduct(payload);


  //   } catch (error) {
  //     console.error('Error deleting task type:', error);
  //   }
  // };

  const patchStock = () => {
    products.forEach(product => {
      if (product.usageType === "PURCHASED-MATERIAL") {
        // Update the stock to 0 for products that are not "PURCHASED-MATERIAL"
        const updatedProduct = {
          ...product,
          stock: 10000,
        };
  
        // Create the payload for the API call
        const payload = {
          "resource": [
            {
              "id": updatedProduct.id,
              "stock": updatedProduct.stock,
            }
          ],
        };
  
        // Log the payload to check
        console.log(payload);
        console.log(updatedProduct);
  
        // Call the backend to update the product stock
        patchProduct(payload)
          .then(res => {
            if (res && res.error) {
              console.error("Error updating product:", res.error);
            }
            if (res && res.resource[0]) {
              console.log(`STOCK UPDATED for product ID: ${updatedProduct.id}`);
            }
          })
          .catch(error => {
            console.error("API error:", error);
          });
      }
    });
  };
  
  // const patchStock = () => {
  //   // Filter products that match the criteria
  //   const makis = products.filter((re) => {
  //     // Parse requirements, defaulting to an empty array if it's undefined or null
  //     const requirements = JSON.parse(re.requirements || '[]');
  
  //     // Check if requirements are empty and usageType is not 'PURCHASED-MATERIAL'
  //     return requirements.length === 0 && re.usageType !== 'PURCHASED-MATERIAL';
  //   });
  
  //   Iterate over the filtered products and delete them using deletePrd(id)
  //   makis.forEach((product) => {
  //     deletePrd(product.id); // Assuming each product has an id property
  //   });
  
  //   Optionally, you can update your product list after deletion
  //   setProducts(makis);
  // };
    

    // const purchasedMaterials = products.filter((re) => re.usageType === 'PURCHASED-MATERIAL');



//     purchasedMaterials.map((prd) => {
//       const payload = {
//         "resource": [

//           {
//             "id": prd.id,
//             "stock":1000
//             // "salesprice": Number(prd.costprice).toFixed(2) * 2 ,
//             // "costprice":Number(prd.costprice).toFixed(2),
//             // "vatClass":24
//           }

//         ],
//       }
// console.log(payload)
// console.log(prd)
//       patchProduct(payload).then(res => {
//         if (res && res.error) {
//           //console.log(res)
//         }
//         if (res && res.resource[0]) {
//           console.log("STOCK UPADTED")
//         }
//       })
//     }
//     )
 // }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const updateProductsCostPrice = async () => {
    setStatus('Updating products...'); // Set status when update starts
    console.log(products);

    // Proceed if products array exists
    await Promise.all(
      products &&
      products.map(async (product) => {
        // Parse the requirements field (if it's a valid JSON string)
        let requirements;
        try {
          requirements = product.requirements ? JSON.parse(product.requirements) : null;
        } catch (error) {
          console.error('Invalid JSON format for requirements', error);
          return product; // Return the product unchanged if there's an error
        }

        if (requirements && Array.isArray(requirements) && requirements.length > 0) {
          // Get the costmargin from the product or default it to 5% if null/undefined
          const costmargin = product.costmargin !== null && product.costmargin !== undefined
            ? product.costmargin
            : 5;

         const matesum = [];
          await Promise.all(
            requirements.map(async (step) => {
              await Promise.all(
                step.materials?.map(async (mats) => {
                  // Find the corresponding product in the product list by mats.id
                  const matchedProduct = products.find(prod => prod.id === mats.id);

                  if (matchedProduct) {
                    const matCost = mats.quantity * matchedProduct.costprice;
                    matesum.push(matCost);
                  } else {
                    console.error(`Product with id ${mats.id} not found in the product list`);
                  }
                })
              );
            })
          );

          // Calculate the final cost
          const totalMaterialCost = matesum.reduce((a, b) => a + b, 0);
          const finalCost = totalMaterialCost + (totalMaterialCost / 100 * costmargin);

          // Prepare payload for patchProduct to update only costprice
          const payload = {
            resource: [
              {
                id: product.id,
                costprice: finalCost,  // Update only the costprice field
              }
            ],
          };

          // Uncomment this to actually send the PATCH request to the backend
          await patchProduct(payload).then(res => {
            if (res && res.error) {
              console.error('Error updating product:', res.error);
            } else {
              console.log(`Product with ID ${product.id} updated with new costprice`);
            }
          });

          // Return the product with the updated costprice
          return {
            ...product,
            costprice: finalCost,  // Update the product's costprice in the state
          };
        } else {
          // Return product unchanged if requirements is not valid or empty
          return product;
        }
      })
    ).then(updatedProducts => {
      setProducts(updatedProducts);
      setStatus('Update completed'); // Set status when update is done
    }).catch(error => {
      setStatus('Error occurred during update'); // Set status if an error occurs
      console.error('Update error:', error);
    });
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <div>
          {/* <Button color="primary" onClick={updateProductsCostPrice}>
            {status || 'Update Products  Live Costs'}
          </Button> */}
          {/* <Button color="primary" onClick={patchStock}>
            PROBLEMATIC PRODUCTS - MISSING STEPS 
          </Button> */}
        </div>
        {/* <Button onClick={updatePurchasedMaterialCostPrice}>Update materials Cost Prices</Button> */}

        <DatatableTables products={products}></DatatableTables>
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
            </div>
          </Col>
        </Row>

      </div>
    </React.Fragment>
  )
}

ProductsList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

export default withRouter(ProductsList)
