import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Collapse
} from "reactstrap";
import { useHistory } from "react-router-dom";
import MapModal from "./mapAddress";
import DataChart from "./charttotals";
import InvoicedByPie from "./invoicebyPie";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import OrderBarChart from "./chart_ordersbydate";
import AddressModal from "./addresses";
import { patchCustomer, getCustomerDetail, getSalesPersons } from "helpers/api_helper";
import { sumTotalOrder, countOrdersByDate, countOrders } from "./reporting";

import EpsilonRedPieChart from "./EpsilonRedPieChart";
import SalesPaymentsBarChart from "./SalesPaymentsBarChart";
const CustomerDetail = (props) => {
  //meta title
  document.title = "Προφίλ | kerino - React Admin & Dashboard Template";
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [name, setName] = useState("");
  const [idx, setIdx] = useState(1);
  const [orders, setOrders] = useState([]);
  const [ordersPending, setOrdersPending] = useState([]);
  const [offers, setOffers] = useState([]);
  const [completeOrders, setCompleteOrders] = useState([]);
  const [customer, setCustomer] = useState();
  const [salespersonlist, setsalespersonslist] = useState([]);
  const [payments, setPayments] = useState([]);
  const [salesperson, setSalesperson] = useState("");
  const {
    match: { params },
  } = props;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCustomer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



  useEffect(() => {
    getSalesPersons().then(res => setsalespersonslist(res));

  }, []);
  useEffect(async () => {
    if (params && params.id) {
      await getCustomerDetail(params.id).then(res => {
        setCustomer(res[0]);
        const orders = res[0].orders;
        const offers = orders.filter((ord) => ord.status === "OFFER");
        const invoiced = orders.filter((ord) => ord.status === "INVOICED" || ord.status === "ON SHIPPING" || ord.status === "PENDING-STOCK");
        const completed = orders.filter((ord) => ord.status === "SHIPPED");
        setOrders(orders);
        setOrdersPending(invoiced);
        setCompleteOrders(completed);
        setSalesperson(res[0].salespersons)

        setOffers(offers);

        // Assuming payments are included in customer details
        setPayments(res[0].payments || []);
      });
    }
  }, [params]);

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const updateCustomer = (customer) => {
    const payload = {
      "resource": [customer],
    };
    patchCustomer(payload).then(res => {
      if (res && res.error) {
        // Handle error
      }
      if (res && res.resource[0]) {
        props.history.push("/customers");
      }
    });
  };

  const counts = orders && orders[0] && countOrders(orders);
  const totalOrders = completeOrders && completeOrders[0] && sumTotalOrder(completeOrders);
  const byDate = orders && orders[0] && countOrdersByDate(orders);

  const totalPaymentsInbound = payments.reduce((acc, payment) => {
    console.log(`Processing payment:`, payment); // Debug output
    const ammount = Number(payment.ammount); // Convert to number
    if (payment.bound === "Inbound") {
      return acc + (isNaN(ammount) ? 0 : ammount); // Add to accumulator if it's a valid number
    }
    return acc;
  }, 0);

  console.log(`Total Payments Inbound: ${totalPaymentsInbound}`); // Debug output


  const customerTotal = totalOrders - totalPaymentsInbound;

  const updateExtraAddresses = (newAddresses) => {
    setCustomer(prevCustomer => ({
      ...prevCustomer,
      extra_address: JSON.stringify(newAddresses),
    }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {customer && (
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="kerino" breadcrumbItem="Λεπτομέρειες Πελάτη" />
            <Row>
              <Col lg="12">
                {error && error ? <Alert color="danger">{error}</Alert> : null}
                {success ? <Alert color="success">{success}</Alert> : null}
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <div className="ms-3">
                            <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <h5>{customer.name}</h5>
                              <p className="mb-1">{email}</p>
                              <p className="mb-0">Αρ. ID: #{idx}</p>
                              {console.log(counts)}
                              <p className="mb-1">Epsilon {counts && counts.totalEpsilon} - ReD: {counts && counts.totalRed}</p>

                              <p className="mb-1">ΣΥΝΟΛΙΚΕΣ ΠΩΛΗΣΕΙΣ Ευρώ: {totalOrders}</p>
                              <p className="mb-1">ΣΥΝΟΛΙΚΕΣ ΠΛΗΡΩΜΕΣ Ευρώ: {totalPaymentsInbound}</p>
                              <p className="mb-1">ΣΥΝΟΛΙΚΟ ΥΠΟΛΟΙΠΟ Πελάτη Ευρώ: {totalOrders - totalPaymentsInbound}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
{counts &&    <Col lg="3">
                        <EpsilonRedPieChart counts={counts} />
                      </Col> }
                   
                      <Col lg="3">
                        <SalesPaymentsBarChart
                          totalOrders={totalOrders}
                          totalPaymentsInbound={totalPaymentsInbound}
                          customerTotal={customerTotal}
                        />
                      </Col>
                    </Row>
                 
     
                    
                  </CardBody>
                </Card>


              </Col>
            </Row>
            <div>
              <Row>
                <Col>
                  <h4 className="card-title mb-4">Λεπτομέρειες Πελάτη</h4>
                </Col>
                <Col>
                  <Button onClick={() => setIsCollapsed(!isCollapsed)}>Επεξεργασία</Button>
                </Col>
              </Row>
            </div>
            <Card>
              <Collapse isOpen={isCollapsed}>
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      // validation.handleSubmit(); // Uncomment if using form validation
                      return false;
                    }}
                  >
                    <div className="form-group">
                      <Label className="form-label">Όνομα</Label>
                      <Input
                        name="name"
                        className="form-control"
                        placeholder="Εισάγετε το όνομα χρήστη"
                        type="text"
                        value={customer.name}
                        onChange={handleInputChange}
                      />
                      <Label className="form-label">Ετικέτα</Label>
                      <Input
                        name="label"
                        className="form-control"
                        placeholder="ετικέτα"
                        type="text"
                        value={customer.label}
                        onChange={handleInputChange}
                      />
                      <Label className="form-label">Ταχυδρομικός Κώδικας</Label>
                      <Input
                        name="postcode"
                        className="form-control"
                        placeholder="ταχυδρομικός κώδικας"
                        type="text"
                        value={customer.postcode}
                        onChange={handleInputChange}
                      />
                      <Label className="form-label">Διεύθυνση</Label>
                      <Input
                        name="address"
                        className="form-control"
                        placeholder="Εισάγετε τη διεύθυνση"
                        type="text"
                        value={customer.address || ''}
                        onChange={handleInputChange}
                      />

<Label className="form-label">TaxOffice</Label>
                      <Input
                        name="address"
                        className="form-control"
                        placeholder="Εισάγετε DOY"
                        type="text"
                        value={customer.taxOffice || ''}
                        onChange={handleInputChange}
                      />
                      <Row style={{ margin: 15 }}>
                        <Col>
                          {isCollapsed && (
                            <MapModal
                              name="address"
                              address={customer.address}
                              onAddressSelect={(a) => {
                                setCustomer(prevState => ({
                                  ...prevState,
                                  address: a
                                }));
                              }}
                            ></MapModal>
                          )}
                        </Col>
                        <Col>
                          <AddressModal onSave={updateExtraAddresses} addresses={customer && customer.extra_address ? JSON.parse(customer.extra_address) : []}></AddressModal>
                        </Col>
                      </Row>
                      <Label className="form-label">Τηλέφωνο</Label>
                      <Input
                        name="phone"
                        className="form-control"
                        placeholder="τηλέφωνο"
                        type="text"
                        value={customer.phone}
                        onChange={handleInputChange}
                      />
                      <Input
                        name="phone2"
                        className="form-control"
                        placeholder="τηλέφωνο2"
                        type="text"
                        value={customer.phone2}
                        onChange={handleInputChange}
                      />
                      <Label className="form-label">ΑΦΜ</Label>
                      <Input
                        name="vat"
                        className="form-control"
                        placeholder="ΑΦΜ"
                        type="text"
                        value={customer.vat}
                        onChange={handleInputChange}
                      />
                      <Label className="form-label">Κατηγορία</Label>
                      <Input
                        name="category"
                        className="form-control"
                        placeholder="Εισάγετε την κατηγορία"
                        type="text"
                        value={customer.category}
                        onChange={handleInputChange}
                      />
                      <Label className="form-label">Επάγγελμα</Label>
                      <Input
                        name="profession"
                        className="form-control"
                        placeholder="Εισάγετε το επάγγελμα"
                        type="text"
                        value={customer.profession}
                        onChange={handleInputChange}
                      />
                      <Label className="form-label">Ηλεκτρονικό Ταχυδρομείο</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Εισάγετε το email"
                        type="text"
                        value={customer.email}
                        onChange={handleInputChange}
                      />




                      <Label className="form-label">Poliths SalesMan</Label>



                      <select
                        id="salespersons"
                        value={salesperson}
                        onChange={(e) => {

                          setSalesperson(e.target.value)
                          setCustomer(prevState => ({
                            ...prevState,
                            salespersons: e.target.value
                          }));
                          
                          }
                        }
                        className="form-control"
                      >
                        <option value="" disabled>
                          Select a salesperson
                        </option>
                        {salespersonlist && salespersonlist.map((person, i) => (
                          <option key={i} value={person.id}>
                            {person.name}
                          </option>
                        ))}
                      </select>
                      <Input name="idx" value={idx} type="hidden" />
                    </div>
                    <div className="text-center mt-4">
                      <Button onClick={() => updateCustomer(customer)} color="danger">
                        Ενημέρωση Πελάτη
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Collapse>
            </Card>

            <Card>
              <CardBody>
                <Label className="form-label">Εκκρεμή Offers</Label>
                <table className="table table-nowrap align-middle mb-0">
                  <tbody>
                    {offers && offers.map((item, i) => {
                      const handleClick = () => {
                        history.push("/order-detail/" + item.id);
                      };

                      return (
                        <tr key={i}>
                          <td style={{ width: "40px" }}>
                            <div className="form-check font-size-16">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id={item.name}
                                value={"s"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item.name}
                              />
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.id}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.created}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{JSON.parse(item.products).length}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{JSON.parse(item.totals).totalOrder}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.status}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.invoicedAt}</div>
                            </h5>
                          </td>
                          <td>
                            <div className="team"></div>
                          </td>
                          <td>
                            <div className="text-center"></div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>



            <Card>
              <CardBody>
                <Label className="form-label">Εκκρεμή Παραγγελίες</Label>
                <table className="table table-nowrap align-middle mb-0">
                  <tbody>
                    {ordersPending && ordersPending.map((item, i) => {
                      const handleClick = () => {
                        history.push("/order-detail", item);
                      };

                      return (
                        <tr key={i}>
                          <td style={{ width: "40px" }}>
                            <div className="form-check font-size-16">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id={item.name}
                                value={"s"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item.name}
                              />
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.id}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.created}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{JSON.parse(item.products).length}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{JSON.parse(item.totals).totalOrder}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.status}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.invoicedAt}</div>
                            </h5>
                          </td>
                          <td>
                            <div className="team"></div>
                          </td>
                          <td>
                            <div className="text-center"></div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Label className="form-label">Ολοκληρωμένες Παραγγελίες</Label>
                <table className="table table-nowrap align-middle mb-0">
                  <tbody>
                    {completeOrders && completeOrders.map((item, i) => {
                      const handleClick = () => {
                        history.push("/order-detail/" + item.id);
                      };

                      return (
                        <tr key={i}>
                          <td style={{ width: "40px" }}>
                            <div className="form-check font-size-16">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id={item.name}
                                value={"s"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item.name}
                              />
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.id}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.created}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{JSON.parse(item.products).length}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{JSON.parse(item.totals).totalOrder}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.status}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.refcode}</div>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <div onClick={handleClick}>{item.invoicedAt}</div>
                            </h5>
                          </td>
                          <td>
                            <div className="team"></div>
                          </td>
                          <td>
                            <div className="text-center"></div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Label className="form-label">Πληρωμές</Label>
                <table className="table table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Ημερομηνία</th>
                      <th>Ποσό</th>
                      <th>Direction</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments && payments.map((payment, i) => (
                      <tr key={i}>
                        <td>{payment.id}</td>
                        <td>{payment.date}</td>
                        <td>{payment.ammount}</td>
                        <td>{payment.bound}</td>
                        <td>{payment.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

CustomerDetail.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(CustomerDetail);
