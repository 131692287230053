import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import DatatableTables from "./datatable"
//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card invoice
import CardCustomer from "./card-customer"
import { getCustomers as onGetCustomers } from "store/actions"

const CustomersList = props => {
   //meta title
   //console.log(props)
   document.title="Custoemers List | customers - React Admin & Dashboard Template";

  const dispatch = useDispatch()

  const { customers } = useSelector(state => ({
    customers: state.customers.customers
  }))

  useEffect(() => {
    dispatch(onGetCustomers())
  }, [dispatch])


  useEffect(() => {
if(customers == 400 || customers == 401 || customers == 403 ) {
  localStorage.removeItem("authUser")
  props.history.push("/login")
}
if(customers && !customers[0]){
  // localStorage.removeItem("authUser")
  // props.history.push("/login")
}
  }, [customers])

     // Check if the user's first name is "estiash"
  
     useEffect(() => {

      const authUser = localStorage.getItem("authUser");
      const parsedAuthUser = authUser ? JSON.parse(authUser) : null;
    
      if (parsedAuthUser && parsedAuthUser.first_name === "estiash") {
        props.history.push("/single/ΕΣΤΙΑΣΗ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "kormakia") {
        props.history.push("/single/ΚΟΡΜΑΚΙΑ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "fitilia") {
        props.history.push("/single/ΦΥΤΙΛΙΑ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "pressa") {
        props.history.push("/single/ΠΡΕΣΣΑ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "plyntirio") {
        props.history.push("/single/ΠΛΥΝΤΗΡΙΟ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "trima") {
        props.history.push("/single/ΤΡΥΜΑ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "tripsimo") {
        props.history.push("/single/ΤΡΙΨΙΜΟ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "benetiko") {
        props.history.push("/single/ΒΕΝΕΤΙΚΟ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "bafhkerino") {
        props.history.push("/single/ΒΑΦΗ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "xytakerino") {
        props.history.push("/single/ΧΥΤΑ");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "extruder") {
        props.history.push("/single/EXTRUDER");
      }
      if (parsedAuthUser && parsedAuthUser.first_name === "plyntirio") {
        props.history.push("/single/ΠΛΥΝΤΗΡΙΟ");
      }
      
      
    }, [props.history]);
    
  //console.log(customers)
  return (
    <React.Fragment>
      <div className="page-content">


          {customers && customers[0] && <DatatableTables products={customers}></DatatableTables>}
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
              </div>
            </Col>
          </Row>
   
      </div>
    </React.Fragment>
  )
}

CustomersList.propTypes = {
  customers: PropTypes.array,
  onGetInvoices: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(CustomersList)
