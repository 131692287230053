import React, { useEffect, useState } from "react"
import { Col, Container, Row, Button } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { getProducts, patchOrder } from "helpers/api_helper"
import { useSelector, useDispatch } from "react-redux"

import { getInvoices as onGetInvoices } from "store/actions"
import OrdersTable from "./ordersTable"
const InvoicesList = props => {
  //meta title
  document.title = "Invoice List | customers - React Admin & Dashboard Template";

  const dispatch = useDispatch()

  const { invoices } = useSelector(state => ({
    invoices: state.invoices.invoices,
  }))

  const [filter, setFilter] = useState("All")
  const [orders, setOrders] = useState([])
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    dispatch(onGetInvoices())
  }, [dispatch])

  const checkAndProcessOrders = async () => {
    setProcessing(true);
    setFilter("All")
    dispatch(onGetInvoices());

    const pendingStockOrders = invoices.filter(order => order.status === "PENDING-STOCK");
    const stockOkOrders = invoices.filter(order => order.status === "STOCK-OK");

    // Processing PENDING-STOCK orders
    for (let order of pendingStockOrders) {
      const products = JSON.parse(order.products);
      let allStockAvailable = true;

      for (let product of products) {
        try {
          const productDetail = await getProducts(product.id);
          const productDetails = productDetail[0];

          if (!productDetails.stock || productDetails.stock < parseFloat(product.quantity)) {
            allStockAvailable = false;
            break;
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
          allStockAvailable = false;
          break;
        }
      }

      if (allStockAvailable) {
        const payload1 = {
          resource: [
            {
              id: order.id,
              status: "STOCK-OK",
            },
          ],
        };
        try {
          await patchOrder(payload1);
          console.log(`Order ${order.id} updated to STOCK-OK`);
        } catch (error) {
          console.error(`Error updating order ${order.id}:`, error);
        }
      } else {
        console.log(`Order ${order.id} cannot be updated due to insufficient stock.`);
      }
    }

    // Processing STOCK-OK orders
    for (let order of stockOkOrders) {
      const products = JSON.parse(order.products);
      let allStockAvailable = true;

      for (let product of products) {
        try {
          const productDetail = await getProducts(product.id);
          const productDetails = productDetail[0];

          if (!productDetails.stock || productDetails.stock < parseFloat(product.quantity)) {
            allStockAvailable = false;
            break;
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
          allStockAvailable = false;
          break;
        }
      }

      if (!allStockAvailable) {
        const payload2 = {
          resource: [
            {
              id: order.id,
              status: "PENDING-STOCK",
            },
          ],
        };
        try {
          await patchOrder(payload2);
          console.log(`Order ${order.id} updated to PENDING-STOCK`);
        } catch (error) {
          console.error(`Error updating order ${order.id}:`, error);
        }
      } else {
        console.log(`Order ${order.id} has sufficient stock.`);
      }
    }
    dispatch(onGetInvoices());
    setOrders(invoices)
    setFilter("STOCK-OK")
    setProcessing(false);
  };



  useEffect(() => {
    if (filter === "All") {
      const inv = invoices.filter((ord) => ord.status != "OFFER-DELETE")
      setOrders(inv)
    }
    if (filter != "All") {
      const inv = invoices.filter((ord) => ord.status === filter)
      setOrders(inv)
    }
  }, [filter])

  useEffect(() => {
    dispatch(onGetInvoices());
    setOrders(invoices)

  }, [])





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row style={{ marginBottom: 20, textAlign: 'center' }}>
            <Col lg="2">
              <Button
                color="primary"
                onClick={() => setFilter("STOCK-OK")}
                style={{ marginRight: '10px' }}
              >
                Έτοιμο για Τιμολόγηση
              </Button>
            </Col>
            <Col lg="1">
              <Button
                color="primary"
                onClick={() => setFilter("OFFER")}
                style={{ marginRight: '10px' }}
              >
                Offers
              </Button>
            </Col>
            <Col lg="1">
              <Button
                color="secondary"
                onClick={() => setFilter("PENDING-STOCK")}
                style={{ marginRight: '10px' }}
              >
                Αναμονή Αποθέματος
              </Button>
            </Col>
            <Col lg="1">
              <Button
                color="info"
                onClick={() => setFilter("INVOICED")}
                style={{ marginRight: '10px' }}
              >
                Τιμολογημένο
              </Button>
            </Col>
            <Col lg="2">
              <Button
                color="warning"
                onClick={() => setFilter("ON SHIPPING")}
                style={{ marginRight: '10px' }}
              >
                Σε Αποστολή
              </Button>
            </Col>
            <Col lg="1">
              <Button
                color="success"
                onClick={() => setFilter("SHIPPED")}
                style={{ marginRight: '10px' }}
              >
                Αποσταλμένο
              </Button>
            </Col>
            <Col lg="1">
              <Button
                color="dark"
                onClick={() => setFilter("All")}
                style={{ marginRight: '10px' }}
              >
                Όλα
              </Button>
            </Col>
            <Col lg="2">
              <Button
                color="primary"
                onClick={checkAndProcessOrders}
                disabled={processing}
              >
                {processing ? "Επεξεργασία..." : "Επεξεργασία Παραγγελιών"}
              </Button>
            </Col>
          </Row>

          <OrdersTable orders={orders} ></OrdersTable>

        </Container>
      </div>
    </React.Fragment>
  )
}

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    ...state
  }
}


export default withRouter(InvoicesList)
