import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Table } from "reactstrap";
import { getCustomers } from "helpers/api_helper";
import ReactApexChart from 'react-apexcharts';

const Dashboard = () => {
  const [customerTotals, setCustomerTotals] = useState([]);
  const [totalSales, setTotalSales] = useState(0);  // To hold total sales
  const [totalIncome, setTotalIncome] = useState(0);  // To hold total income (payments)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customers = await getCustomers();
        processCustomerData(customers);
      } catch (error) {
        console.error('Σφάλμα κατά την ανάκτηση δεδομένων:', error);
      }
    };

    fetchData();
  }, []);

  const processCustomerData = (customers) => {
    // Φιλτράρισμα πελατών με παραγγελίες που έχουν status "SHIPPED"
    const customersWithShippedOrders = customers.filter(customer => 
      customer.orders.some(order => order.status === 'SHIPPED')
    );
  
    // Υπολογισμός συνολικών παραγγελιών, πληρωμών και πλήθους παραγγελιών για πελάτες με παραγγελίες "SHIPPED"
    const totals = customersWithShippedOrders.map(customer => {
      const shippedOrders = customer.orders.filter(order => order.status === 'SHIPPED');
  
      const { totalOrders, orderCount } = shippedOrders.reduce((acc, order) => {
        try {
          const parsedTotals = JSON.parse(order.totals);
          return {
            totalOrders: acc.totalOrders + (Number(parsedTotals.totalOrder) || 0),
            orderCount: acc.orderCount + 1
          };
        } catch (error) {
          console.error('Σφάλμα κατά την ανάλυση των συνολικών παραγγελιών:', error);
          return acc;
        }
      }, { totalOrders: 0, orderCount: 0 });
  
      const totalPayments = customer.payments.reduce((acc, payment) => acc + (Number(payment.ammount) || 0), 0);
  
      return {
        id: customer.id,
        name: customer.name,
        totalOrders,
        totalPayments,
        orderCount,
        totalCustomer: totalOrders - totalPayments
      };
    });

    // Calculate total sales and total income from all customers
    const totalSales = totals.reduce((acc, customer) => acc + customer.totalOrders, 0);
    const totalIncome = totals.reduce((acc, customer) => acc + customer.totalPayments, 0);

    setTotalSales(totalSales); // Set the total sales
    setTotalIncome(totalIncome); // Set the total income
    
    // Ταξινόμηση των συνολικών παραγγελιών κατά φθίνουσα σειρά
    const sortedTotals = totals.sort((a, b) => b.totalOrders - a.totalOrders);
  
    setCustomerTotals(sortedTotals);
  };
  
  // Επιλογές γραφήματος
  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: false, // Ensure bars are grouped
    },
    xaxis: {
      categories: customerTotals.map(customer => customer.name),
      title: {
        text: 'Πελάτες'
      }
    },
    yaxis: {
      title: {
        text: 'Συνολική Αξία (€)'
      },
      labels: {
        formatter: (value) => value.toFixed(2) // Μορφοποίηση ετικετών άξονα y σε δύο δεκαδικά ψηφία
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => value.toFixed(2) // Μορφοποίηση ετικετών δεδομένων σε δύο δεκαδικά ψηφία
    },
    title: {
      text: 'Συνολική Αξία Παραγγελιών, Πληρωμών και Υπολοίπου ανά Πελάτη',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    },
    colors: ['#33C1FF', '#FF5733', '#4CAF50'], // Blue for Total Orders, Red for Payments, Green for Total Customer
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      floating: true,
      offsetY: -10
    }
  };

  // Δεδομένα γραφήματος
  const chartData = [
    {
      name: 'Συνολική Αξία Παραγγελιών',
      data: customerTotals.map(customer => customer.totalOrders)
    },

    {
      name: 'Υπόλοιπο',
      data: customerTotals.map(customer => customer.totalCustomer)
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div style={{ marginTop: '20px' }}>
            <ReactApexChart options={chartOptions} series={chartData} type="bar" height={350} />
          </div>

          <h4>Σύνολα Πελατών</h4>
          <Table className="align-middle mb-0">
            <thead>
              <tr>
                <th>Όνομα Πελάτη</th>
                <th>Σύνολο Παραγγελιών</th>
                <th>Συνολική Αξία Παραγγελιών</th>
                <th>Συνολικές Πληρωμές</th>
                <th>Υπόλοιπο</th>
              </tr>
            </thead>
            <tbody>
              {customerTotals.map((customer) => (
                <tr key={customer.id}>
                  <td>{customer.name}</td>
                  <td>{customer.orderCount}</td>
                  <td>{Number(customer.totalOrders).toFixed(2)} €</td>
                  <td>{Number(customer.totalPayments).toFixed(2)} €</td>
                  <td>{Number(customer.totalCustomer).toFixed(2)} €</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Display total sales and total income */}
          <div style={{ marginTop: '20px' }}>
            <h5><strong>Συνολικές Πωλήσεις: </strong>{Number(totalSales).toFixed(2)} €</h5>
            <h5><strong>Συνολικό Εισόδημα (Πληρωμές): </strong>{Number(totalIncome).toFixed(2)} €</h5>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
